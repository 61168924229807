import axios from 'axios'
import {getTimestampHash} from './url_timestamp_helper'
import {FST_REQUEST_AUTH_HEADER} from 'assets/js/constants'

export default {
  onRequest: async (config: any) => {
  // Add timestamp hash header
    const result = await getTimestampHash(axios.getUri({...config.options, url: config.request}))
    if (result && result.hash && result.timestamp) {
      if (config) {
        config.options.headers = config.options.headers || {}
        config.options.headers[FST_REQUEST_AUTH_HEADER.HASH] = result.hash
        config.options.headers[FST_REQUEST_AUTH_HEADER.TIMESTAMP] = result.timestamp
      }
    }
    return config
  }
}
